import axios from 'axios';

const url = process.env.REACT_APP_URL;
const api = axios.create({
    baseURL: url,
    headers: { 
        'Content-Type': 'application/json',
        'Accept': '*/*',
    },
    data: undefined,


});

export default api;