import React, { useEffect, useState, Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import api from '../Api/api';
import Spinner from 'react-bootstrap/Spinner'
import FadeIn from 'react-fade-in';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CgSmileSad } from 'react-icons/cg';
import picpay from './../assets/LogoPicPayfundoverde.png'
import pix from './../assets/pix.png'
import { v1 as uuidv1 } from 'uuid';
import { Redirect } from 'react-router'


import Cabecalho from '../components/Cabecalho'
import Rodape from '../components/Rodape'
import { BACKEND } from '../constants'



class Picpay extends Component {

    state = {
        valores: null,
        error: false,
        warning: false,
        paymentwarning: false,
        responseapi: null,
        redirect: false
    }


    async componentDidMount() {
        const status204 = "Abastecimento não encontrado";
        api.get('/Delivery/LastDeliveryBySerialNumber/' + this.props.match.params.serialnumber)
            .then(response => {
                if (response.status == 204) {
                    this.setState({ warning: true });
                    toast.warning(status204, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                this.setState({ valores: response.data });

            })
            .catch(error => {
                this.setState({ error: true });
                toast.error(error.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

    }

    render() {
        const status204 = "Abastecimento já pago";
        const val = this.state;
        const error = this.state.error;
        const paymentwarning = this.state.paymentwarning;
        const warning = this.state.warning;
        var windowReference;
        var workerpullingpicpaystatus;
        const pullingpicpaystatus = () => {
            const responseapi = this.state.responseapi;
            var currentdate = new Date(Date.now()).toISOString();
            if(currentdate <= responseapi.expiresAt){
                api.get('/PicPay/Payment/Status/' + responseapi.referenceId)
                .then(response => {
                    if (response.status != 204 && response.data.isPaid) {
                        console.log(response.data);
                        windowReference.close();
                        clearInterval(workerpullingpicpaystatus);
                        this.props.history.push('/Comprovante/' + responseapi.referenceId)
                    }
                })
                .catch(error => {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
            else{
                clearInterval(workerpullingpicpaystatus);
                windowReference.close();
            }

        }
        const SafariBrowserPicpayRequest = () => {
            windowReference = window.open("about:blank","_blank");
            api.post('/PicPay/Payment', {
                serialNumber: this.props.match.params.serialnumber,
                currentPageDateTime: val.valores.currentPageDateTime
            })
                .then((response) => {
                    if (response.status == 204) {
                        this.setState({ paymentwarning: true });
                        toast.warning(status204, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        windowReference.close();
                    }else{
                        windowReference.location = response.data.paymentUrl;
                        console.log(response.data);
                        this.setState({
                            responseapi: response.data,
                            redirect: true
                        });

                        workerpullingpicpaystatus = setInterval(pullingpicpaystatus, 5000);
                    }
                })
                .catch((err) => {
                    toast.error(err.toString(), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    windowReference.close();
                });
        }
        const SafariBrowserPixRequest = () => {
            api.post('/Pix/generate_pix', {
                serialNumber: this.props.match.params.serialnumber,
                currentPageDateTime: val.valores.currentPageDateTime
            })
                .then((response) => {
                    if (response.status == 204) {
                        this.setState({ paymentwarning: true });
                        toast.warning(status204, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }else{
                        const txtid = response.data.data.txid;
                        const url = response.data.data.pixCopiaECola;
                        const state = response.data.data.solicitacaoPagador;
                        const total = response.data.data.valor.original;
                        const search = '/';
                        const replaceWith = '_';
                        const result = url.split(search).join(replaceWith);
                        const stateurl = state.split(search).join(replaceWith);
                        
                        //const urlpix = url.replaceALL("/", "_");
                        this.props.history.push('/Pix/' + result + '/' + txtid + '/' + stateurl + '/' + total);
                        console.log(response.data.data.pixCopiaECola);
                        console.log(result);
                    }
                })
                .catch((err) => {
                    toast.error(err.toString(), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
        return (

            <>
                <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />

                {error &&
                    <table style={{ height: "100vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle"> <h2 style={{ color: "#3DB0C1" }}>Por Favor Tente recarregar a pagina.<CgSmileSad /></h2></td>
                            </tr>
                        </tbody>
                    </table>

                }
                {
                    paymentwarning &&
                    <table style={{ height: "100vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle"> <h2 style={{ color: "#3DB0C1" }}>já foi realizado o pagamento desse abastecimento.</h2><h2 style={{ color: "#3DB0C1" }}>Por favor tente recarregar a pagina.</h2></td>

                            </tr>
                            <tr className="Warning">
                                <td className="text-center align-middle" ></td>
                            </tr>
                        </tbody>
                    </table>

                }
                {
                    warning &&
                    <table style={{ height: "100vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle"> <h2 style={{ color: "#3DB0C1" }}>Não foi possível encontrar um abastecimento sem pagamento realizado.</h2><h2 style={{ color: "#3DB0C1" }}>Por favor tente recarregar a pagina.</h2></td>

                            </tr>
                            <tr className="Warning">
                                <td className="text-center align-middle" ></td>
                            </tr>
                        </tbody>
                    </table>
                }
                {
                    !val.valores && !warning && !paymentwarning && !error &&
                    <table style={{ height: "100vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle">   <Spinner animation="border" variant="primary" /></td>
                            </tr>
                        </tbody>
                    </table>

                }
                {
                    val.valores &&
                    <div>
                        <FadeIn
                            // className="col-lg-12 col-12 col-xs-12 col-sm-12 col-md-12"
                            transitionDuration="3000"
                            style={{ height: "100vh", width: "100%" }}
                        >
                            <Container className="corpo">
                                <Cabecalho />
                                <Row style={{ color: "#3DB0C1", marginTop: "40px" }}>
                                    <Col lg={12} xs={12} sm={12} md={12}>
                                        <div className="text-center" style={{ fontWeight: "800" }}>RESUMO DO ABASTECIMENTO</div>
                                    </Col>
                                    <Col className="titulosFormulario" lg={4} xs={12} sm={4} md={4}>
                                        <div className="text-left" style={{ fontWeight: "400" }}> ESTABELECIMENTO:</div>
                                    </Col>
                                    <Col className="camposFormulario" lg={6} xs={12} sm={6} md={6}>
                                        <div className="text-left" style={{ fontWeight: "400" }} > {val.valores.deliveriesServicesProvider.stationName}</div>
                                    </Col>
                                    <div className="pontilhado text-top"></div>
                                    <Col className="titulosFormulario" lg={4} xs={12} sm={4} md={4}>
                                        <div className="text-left" style={{ fontWeight: "400" }}>  COMBUSTIVEL:</div>
                                    </Col>
                                    <Col className="camposFormulario" lg={6} xs={12} sm={6} md={6}>
                                        <div className="text-left" style={{ fontWeight: "400" }}> {val.valores.deliveriesServicesProvider.fuelDescription}</div>
                                    </Col>
                                    <div className="pontilhado"></div>
                                    <Col className="titulosFormulario" lg={4} xs={8} sm={4} md={4}>
                                        <div className="text-left" style={{ fontWeight: "400" }}>  VALOR UNITARIO:</div>
                                    </Col>
                                    <Col className="camposFormulario" lg={6} xs={4} sm={6} md={6}>
                                        <div className="text-left" style={{ fontWeight: "400" }}>R$ {val.valores.deliveriesServicesProvider.price.toFixed(3).toString().replace(".", ",")}</div>
                                    </Col>
                                    <div className="pontilhado"></div>
                                    <Col className="titulosFormulario" lg={4} xs={8} sm={4} md={4}>
                                        <div className="text-left" style={{ fontWeight: "400" }}> VOLUME ABASTECIDO:</div>
                                    </Col>
                                    <Col className="camposFormulario" lg={6} xs={4} sm={6} md={6}>
                                        <div className="text-left" style={{ fontWeight: "400" }}>{val.valores.deliveriesServicesProvider.volume.toFixed(3).toString().replace(".", ",")} L </div>
                                    </Col>
                                    <div className="pontilhado"></div>
                                    <Col className="titulosFormulario" lg={4} xs={8} sm={4} md={4}>
                                        <div className="text-left" style={{ fontWeight: "400" }}> VALOR TOTAL:</div>
                                    </Col>
                                    <Col className="camposFormulario" lg={6} xs={4} sm={6} md={6}>
                                        <div className="text-left" style={{ fontWeight: "400" }}>{new Intl.NumberFormat('pt-BR',{style: 'currency', currency: 'BRL'}).format(val.valores.deliveriesServicesProvider.total)}</div>
                                    </Col>
                                    <div className="pontilhado"></div>
                                    <Col className="titulosFormulario" lg={4} xs={5} sm={4} md={4}>
                                        <div className="text-left" style={{ fontWeight: "400" }}> DATA:</div>
                                    </Col>
                                    <Col className="camposFormulario" lg={6} xs={7} sm={6} md={6}>
                                        <div className="text-left" style={{ fontWeight: "400" }}>{val.valores.deliveriesServicesProvider.dateFormat}</div>
                                    </Col>
                                </Row>
                            </Container>
                        </FadeIn>
                        <Container fluid className="fixed-bottom" style={{ height: "24vh" }}>
                            <Row style={{ backgroundColor: "white", color: "#023d81", borderTopLeftRadius: "9%", borderTopRightRadius: "9%", height: "100%" }}>
                                <Col lg={12} xs={12} sm={12} md={12}>
                                    <table style={{ height: "100%", width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td className="text-center align-bottom rodapeTitulo" style={{ fontWeight: "800" }} >   PAGUE COM</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                {/* <Col lg={6} xs={6} sm={6} md={6}>
                                    <div className="text-center">  <img className="RodapeButton" src={picpay} onClick={SafariBrowserPicpayRequest} /></div>
                                </Col> */}
                                <Col lg={12} xs={12} sm={12} md={12}>
                                    <div className="text-center">  <img className="RodapeButton" src={pix} onClick={SafariBrowserPixRequest} /></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }

            </>
        )
    }
}
export default Picpay
