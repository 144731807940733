import React, { useEffect, useState, Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { SMTPClient } from 'emailjs';
import api from '../Api/api';
import Spinner from 'react-bootstrap/Spinner'
import FadeIn from 'react-fade-in';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CgSmileSad } from 'react-icons/cg';
import pix from './../assets/pix_semfundo.png'
import apiKey from '../Api/apiemails';
import axios from 'axios';

import Cabecalho from '../components/Cabecalho'
import Rodapecomp from '../components/Rodapecomp'
import { BACKEND } from '../constants'



class Comprovante extends Component {

    state = {
        valores: null,
        error: false,
        email: '',
    }
    async componentDidMount() {
        const status204 = "Abastecimento não encontrado";
        api.get('/PicPay/Invoice/' + this.props.match.params.referenceId)
            .then(response => {
                if (response.status == 204) {
                    this.setState({ warning: true });
                    toast.warning(status204, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                this.setState({ valores: response.data });

            })
            .catch(error => {
                this.setState({ error: true });
                toast.error(error.toString(), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

    }


    render() {
        const val = this.state.valores;
        const error = this.state.error;
        const email = this.state.email;
        const handleSubmit = (event) => {
            axios({
                method: "POST",
                url: process.env.EMAIL_URL,
                dataraw: {
                    "to": email,
                    "user_id": process.env.USER_ID,
                    "service_id": process.env.SERVICE_ID,
                    "template_id": process.env.TEMPLATE_ID,
                    "message": val,
                }
            }).then((response) => {
                if (response.data.msg === 'success') {
                    alert("Email sent, awesome!");
                    this.resetForm()
                } else if (response.data.msg === 'fail') {
                    alert("Oops, something went wrong. Try again")
                }
            })
        }
        return (

            <>
                <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />

                {
                    error &&
                    <table style={{ height: "70vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle"> <h2 style={{ color: "#3DB0C1" }}>Por Favor Tente recarregar a pagina.<CgSmileSad /></h2></td>
                            </tr>
                        </tbody>
                    </table>

                }
                {
                    !val &&
                    <table style={{ height: "70vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle">   <Spinner animation="border" variant="primary" /></td>
                            </tr>
                        </tbody>
                    </table>

                }
                {
                    val &&
                    <FadeIn
                        // className="col-lg-12 col-12 col-xs-12 col-sm-12 col-md-12"
                        transitionDuration="3000"
                        style={{ height: "100vh", width: "100%" }}
                    >
                        <Container className="corpo">
                            <Cabecalho />
                            <Row className="tituloComp">
                                <Col lg={12} xs={12} sm={12} md={12}>
                                    <h6 className="text-center" style={{ fontWeight: "800" }}> COMPROVANTE DE PAGAMENTO</h6>
                                </Col>
                            </Row>
                            <Container className="corpocomp">
                                <Row className="comprovante" >
                                    <Col lg={7} xs={7} sm={7} md={7} style={{ marginTop: "4vh" }}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>VIA CLIENTE <br></br> {val.state}</b></div>
                                    </Col>
                                    <Col lg={5} xs={5} sm={5} md={5} style={{ marginTop: "4vh" }}>
                                        <img className="align-right RodapeLogoComp" src={pix}></img>
                                    </Col>
                                </Row>
                                <Row className="comprovante" style={{ marginTop: "1vh" }}>
                                    <Col lg={12} xs={12} sm={12} md={12}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>{val.stationName}</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={12} xs={12} sm={12} md={12}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>CNPJ: {val.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={12} xs={12} sm={12} md={12}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>{val.dateFormat}</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={12} xs={12} sm={12} md={12}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>{val.fuelName}</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={12} xs={12} sm={12} md={12}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>BICO {val.hoseNumber}</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={8} xs={8} sm={8} md={8}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>VALOR UNITARIO</b></div>
                                    </Col>
                                    <Col lg={4} xs={4} sm={4} md={4}>
                                        <div className="text-right" style={{ fontWeight: "200" }}><b>R$ {val.price.toFixed(3).toString().replace(".", ",")}</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={8} xs={8} sm={8} md={8}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>VOLUME ABASTECIDO</b></div>
                                    </Col>
                                    <Col lg={4} xs={4} sm={4} md={4}>
                                        <div className="text-right" style={{ fontWeight: "200" }}><b>{val.volume.toFixed(3).toString().replace(".", ",")} L</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={8} xs={8} sm={8} md={8}>
                                        <div className="text-left" style={{ fontWeight: "200" }}><b>VALOR TOTAL</b></div>
                                    </Col>
                                    <Col lg={4} xs={4} sm={4} md={4}>
                                        <div className="text-right" style={{ fontWeight: "200" }}><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val.total)}</b></div>
                                    </Col>
                                </Row>
                                <Row className="comprovante">
                                    <Col lg={6} xs={6} sm={6} md={6}>
                                        <div className="text-left" style={{ fontWeight: "200" }}>
                                            <b>{(val.deliveryauthid > 0) || (val.providerAutorizationCode > 0) ? 'AUT: ' : ''}</b>
                                            <b>{(val.deliveryauthid > 0) ? val.deliveryauthid : ''}</b>
                                            <b>{(val.deliveryauthid <= 0) && (val.providerAutorizationCode > 0) ? '-' : ''}</b>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={6} sm={6} md={6}>
                                        <div className="text-right" style={{ fontWeight: "200" }}>
                                            <b>{(val.deliveryauthid > 0) || (val.providerAutorizationCode > 0) ? 'NSU: ' : ''}</b>
                                            <b>{(val.providerAutorizationCode > 0) ? val.providerAutorizationCode : ''}</b>
                                            <b>{(val.deliveryauthid > 0) && (val.providerAutorizationCode <= 0) ? '-' : ''}</b>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </FadeIn>
                }

            </>
        )
    }
}
export default Comprovante
