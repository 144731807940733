import React from 'react'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import logo from './../assets/logoconecttec.svg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
  MdDescription, MdHome,
  MdMailOutline, MdCollectionsBookmark
} from 'react-icons/md'

const Cabecalho = () => {
  return (
    <Row style={{ color: "#00BFFF" }}>
      <Col lg={6} xs={6} sm={6} md={6}>
        <img src={logo} className="cabecalhoLogo"></img> 
      </Col>
    </Row>

  )
}

export default Cabecalho