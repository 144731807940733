import axios from 'axios';
import React, { useEffect, useState, Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import api from '../Api/api';
import Spinner from 'react-bootstrap/Spinner'
import FadeIn from 'react-fade-in';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imagepix from './../assets/pix_semfundo.png'
import { CgSmileSad } from 'react-icons/cg';
import picpay from './../assets/LogoPicPayfundoverde.png'
import pix from './../assets/pix.png'
import { v1 as uuidv1 } from 'uuid';
import { Redirect } from 'react-router'
import copy from "copy-to-clipboard";


import Cabecalho from '../components/Cabecalho'
import Rodape from '../components/Rodape'
import { BACKEND } from '../constants'



class Pix extends Component {

    state = {
        valores: this.props.match.params.pix,
        error: false,
        email: '',
    }

    render() {
        const status204 = "Abastecimento já pago";
        var workerpullingpix;
        const [copyText, setCopyText] = [''];
        const val = this.props.match.params.pix;
        const state = this.props.match.params.state;
        const total = this.props.match.params.total;
        const error = this.state.error;
        const email = this.state.email;
        const search = '_';
        const replaceWith = '/';
        const result = val.split(search).join(replaceWith);
        const resultstate = state.split(search).join(replaceWith);
        const statefinal = resultstate.substr(resultstate.indexOf('>') + 1);
        const stationname = pegarAteEspaco(resultstate, "<");
        function pegarAteEspaco(frase, separador) {
            return frase.split(separador, 1)[0]
        }
        const handleCopyText = (e) => {
            setCopyText(e.target.value);
        }
        const pullingpix = () => {
            api.get('/Pix/Payment/' + this.props.match.params.txtid)
                .then(response => {
                    if (response.status == 200 && response.data.data.status == "CONCLUIDA") {
                        console.log(response.data);
                        clearInterval(workerpullingpix);
                        this.props.history.push('/ComprovantePix/' + this.props.match.params.txtid)
                    }
                    else {
                        workerpullingpix = setInterval(pullingpix, 8000);
                    }
                })
                .catch(error => {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }

        const copyToClipboard = () => {
            copy(result);
            const copiado = 'Copiado com sucesso';
            toast.success(copiado, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            pullingpix();
        }
        return (

            <>

                <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />

                {
                    error &&
                    <table style={{ height: "70vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle"> <h2 style={{ color: "#3DB0C1" }}>Por Favor Tente recarregar a pagina.<CgSmileSad /></h2></td>
                            </tr>
                        </tbody>
                    </table>

                }
                {
                    !val &&
                    <table style={{ height: "70vh", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="text-center align-middle">   <Spinner animation="border" variant="primary" /></td>
                            </tr>
                        </tbody>
                    </table>

                }
                {
                    val &&
                    <div>
                        <Container className="corpo">
                            <Cabecalho />
                            <Row className="tituloComp">
                                <Col lg={12} xs={12} sm={12} md={12}>
                                    <h6 className="text-center" style={{ fontWeight: "800" }}> PAGAMENTO VIA  <img className="align-right PixLogo" src={imagepix}></img></h6>

                                </Col>
                            </Row>
                            <Row className="camposPix">
                                <Col lg={12} xs={12} sm={12} md={12}>
                                    <h6 className="text-center" style={{ fontWeight: "600" }}> {stationname}</h6>

                                </Col>
                            </Row>
                            <Row className="camposState">
                                <Col lg={12} xs={12} sm={12} md={12}>
                                    <h6 className="text-center" style={{ fontWeight: "600" }}> {statefinal}</h6>

                                </Col>
                            </Row>
                            <Row className="camposPix">
                                <Col lg={12} xs={12} sm={12} md={12}>
                                    <h6 className="text-center" style={{ fontWeight: "600" }}> VALOR TOTAL: R$ {total}</h6>

                                </Col>
                            </Row>
                            <Col lg={12} xs={12} sm={12} md={12}>
                                <textarea className="Input1" style={{ fontWeight: "500" }} rows="7" cols="33">
                                    {result}
                                </textarea>
                                <div className="Button"
                                    onClick={copyToClipboard} style={{ fontWeight: "600" }}>
                                    Copiar Código
                            </div>
                            </Col>
                        </Container>
                    </div>
                }

            </>
        )
    }
}
export default Pix
