import axios from 'axios';

const url = process.env.EMAIL_URL;
const user = process.env.USER_ID;
const service = process.env.SERVICE_ID;
const template = process.env.TEMPLATE_ID;
const api = axios.create({
    baseURL: url,
    headers: { 
        'Content-Type': 'application/json',
        'Accept': '*/*',
    },
    dataraw: {
        "user_id": user,
        "service_id": service,
        "template_id": template,
    },


});

export default api;