import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Inicio from '../pages/Inicio'
import Home from '../pages/Home'
import Picpay from '../pages/PicPay'
import Comprovante from '../pages/Comprovante'
import ComprovantePix from '../pages/ComprovantePix'
import Pix from '../pages/Pix'

export default function Rotas(){
    return (
        <BrowserRouter>
            <Switch>
                {/* <Route exact path="/:serialnumber" component={Picpay} /> */}
                <Route exact path="/:serialnumber" component={Picpay} />
                <Route exact path="/Comprovante/:referenceId" component={Comprovante} />
                <Route exact path="/ComprovantePix/:referenceId" component={ComprovantePix} />
                <Route exact path="/Pix/:pix/:txtid/:state/:total" component={Pix} />
            </Switch>
        </BrowserRouter>
    )
}