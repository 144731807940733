import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Rotas from './routes/Rotas'
import './css/Layout.css'

const App = () => {
  return (
    <Rotas />
  )
}

export default App